p {
  margin-bottom: .5rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-family;
  font-weight: $extraBold;
}

h1, .h1 {
  font-size: px-to-rem(30);
  line-height: px-to-rem(35);
  margin: px-to-rem(30) 0 px-to-rem(20);
}

h2, .h2 {
  font-size: px-to-rem(27);
  line-height: px-to-rem(30);
  margin: px-to-rem(20) 0 px-to-rem(10);
}

h3, .h3{
  font-size: px-to-rem(22);
  line-height: px-to-rem(27);
  margin: px-to-rem(20) 0 px-to-rem(10);
}

h6, .h6 {
  font-size: px-to-rem(14);
  line-height: px-to-rem(18);
  margin: 0;
  border: 1px solid purple;
}

.text--uppercase {
  text-transform: uppercase;
}