.CookieDisclaimer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: map-get($z-indexes, 'cookie-disclaimer');
  width: 100%;
  background-color: $white;
  color: $black;
  box-shadow: 0rem 0.5rem 0.5rem rgba($black, 0.25);
}

.CookieDisclaimer__content {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;

  @include mq($from: tablet) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.CookieDisclaimer__title {
  margin-bottom: 0.5rem;
  flex: 0 0 auto;
  font-weight: bold;

  @include mq($from: tablet) {
    width: 25%;
  }
}

.CookieDisclaimer__copy-container {
  flex: 1 1 auto;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.CookieDisclaimer__title,
.CookieDisclaimer__copy-container {
  @include mq($from: tablet) {
    padding-right: 1rem;
  }
}

.CookieDisclaimer__copy {
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.CookieDisclaimer__buttons {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1 1 auto;

  @include mq($from: tablet) {
    width: 25%;
    justify-content: space-between;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.CookieDisclaimer__decline,
.CookieDisclaimer__accept {
  width: 100%;
}

.CookieDisclaimer__text-link {
  &, &:visited {
    color: $gold;
  }
}
