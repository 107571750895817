.Checkbox {
  position: relative;
}

.Checkbox__label {
  cursor: pointer;
}

.Checkbox__input {
  opacity: 0;
  position: absolute;
}

.Checkbox__text {
  padding-left: px-to-rem(32);
  display: block;
  text-align: left;
  font-size: px-to-rem(12);
  line-height: 1rem;
  @include mq($from: tablet) {
    font-size: $base-font-size;
    line-height: px-to-rem($base-line-height);
  }
}

.Checkbox__control {
  position: absolute;
  display: block;
  width: px-to-rem(20);
  height: px-to-rem(20);
  margin-right: px-to-rem(12);
  margin-top: px-to-rem(2);
  background-color: inherit;
  color: $black;
  border: px-to-rem(2) solid;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: px-to-rem(3);
    left: px-to-rem(3);
    width: px-to-rem(10);
    height: px-to-rem(10);
    background-color: $black;
    opacity: 0;
    transition: opacity .3s ease-out;
  }
}

.Checkbox__input:checked+.Checkbox__control:after {
  opacity: 1;
}

.Checkbox__input:checked+.Checkbox__control {
  border-color: $black;
}

.Checkbox__error { // Matching the MUI textfield error styling
  margin: 0.5rem 0;
  background-color: $red;
  color: $white;
  padding: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
}