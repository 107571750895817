.Layout {
  color: $black;
}

.Layout__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding-top: px-to-rem(30);
  padding-bottom: px-to-rem(30);
}


.Layout__frame {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  z-index: map-get($z-indexes, 'layout');
}

.Layout__frame--cracker {
  justify-content: space-between;

  .Cracker__half {
    height: px-to-rem(182);
  }
  .Cracker__half--top {
    bottom: initial;
  }

  .Cracker__half--bottom {
    top: initial;
  }
}

.Layout__snowflakes {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  
  &:after,
  &:before {
    @include mq($until: tablet) {
      content: ' ';
      display: block;
      width: px-to-rem(204);
      height: px-to-rem(223);
      position: absolute;
      background-image: url('../../images/snowflake.png');
      background-repeat: no-repeat;
    }
  }

  &:before {
    top: -#{px-to-rem(50)};
    left: -#{px-to-rem(70)};
  }

  &:after {
    bottom: -#{px-to-rem(50)};
    right: -#{px-to-rem(70)};
  }

  .container {
    position: absolute;
    height: 100vh;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &:after,
    &:before {
      @include mq($from: tablet) {
        content: ' ';
        display: block;
        width: px-to-rem(204);
        height: px-to-rem(223);
        position: absolute;
        background-image: url('../../images/snowflake.png');
        background-repeat: no-repeat;
      }
    }

    &:before {
      top: -#{px-to-rem(50)};
      left: -#{px-to-rem(70)};
    }

    &:after {
      bottom: -#{px-to-rem(50)};
      right: -#{px-to-rem(70)};
    }
  }
}

.Layout__radial {
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%);
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 1;
}