.Cracker__ui {
  background: none;
  position: relative;
}

.Cracker__half {
  background-repeat: no-repeat;
  background-size: cover;
  width: px-to-rem(139);
  height: px-to-rem(359);
  display: block;
  position: relative;
}

.Cracker__half--top {
  transform-origin: bottom;
  background-image: url('../../images/cracker-half-top.png');
  bottom: -1rem;
  background-position: bottom;
}

.Cracker__half--bottom {
  transform-origin: top;
  background-image: url('../../images/cracker-half-bottom.png');
  top: -1rem;
  background-position: top;
}

.Cracker__merryxmas {
  display: block;
  background-image: url('../../images/merry-christmas.png');
  background-size: contain;
  width: px-to-rem(169);
  height: px-to-rem(169);
  position: absolute;
  top: px-to-rem(-6);
  left: px-to-rem(-14);
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: map-get($z-indexes, 'merry-christmas');
}

.Cracker__instructions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: px-to-rem(264);
  height: px-to-rem(200);
  padding: 1rem;
  margin: auto;
  background: url('../../images/bg-instructions.svg');
  background-size: cover;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity .3s ease-out;
  cursor: pointer;
  z-index: map-get($z-indexes, 'instructions');
}

.Cracker__confetti-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.Cracker__confetti {
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  z-index: map-get($z-indexes, 'confetti');
}

.Cracker__help {
  opacity: 0;
  transition: opacity .3s ease-out;
  position: absolute;
  border: px-to-rem(1) solid $black;
  background-color: rgba($white, 0.3);
  width: px-to-rem(231);
  font-size: px-to-rem(15);
  font-family: $heading-font-family;
  left: -#{px-to-rem(46)};
  color: $black;
  font-weight: $extraBold;
  top: px-to-rem(60);
  padding: px-to-rem(10);
  z-index: map-get($z-indexes, 'instructions');
}