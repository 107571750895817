// Blacks:
$black: #000000;

// Gold:
$gold: #CFA75D;

// Red:
$red: #DD0000;

// Whites:
$white: #ffffff;