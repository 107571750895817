/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
// Eric Mayer's reset stylesheet that can be 
// extended where necessary.
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: $base-font-family;
	font-weight: $normal;
	vertical-align: baseline;
    word-wrap: break-word;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, canvas {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
    quotes: none;

    &:before,
    &:after {
        content: '';
	    content: none;
    }
}

blockquote, q {
	quotes: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
    box-sizing: border-box;
}

strong {
	font-weight: $bold;
}

i {
	font-style: $italic;
}

canvas {
	outline: 0;
	box-shadow: none;
}

iframe,
video {
	max-width: 100%;
	height: auto;
}
