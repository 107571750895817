// Font families
$base-font-family: 'Montserrat', Helvetica, Arial, sans-serif;
$heading-font-family: 'Montserrat', Helvetica, Arial, sans-serif;

// Font sizes
$base-font-size: 17px;

// Line heights
$base-line-height: 22px;

// Font weights
$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;
$extraBold: 800;

// Font styles
$normal: normal;
$italic: italic;